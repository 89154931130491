import React from "react"
import { Col, Container, Row } from "reactstrap"
import Layout from "../components/layout/layout"
import SEO from "../components/seo/seo"

const ImpressumPage = () => (
  <Layout hideAdBlock={true}>
    <SEO title="Page two" />
    <Container>
      <Row>
        <Col>
          <h2>Angaben gemäß § 5 TMG:</h2>

          <p>Johannes Hinterstößer</p>

          <p>
            Diese Person ist ebenfalls inhaltlich Verantwortlicher gemäß § 55
            RStV.
          </p>

          <h3>Postanschrift:</h3>

          <p>
            Setzbergstr. 9<br />
            81539 München
          </p>

          <h3>Kontakt:</h3>

          <p>
            Telefon: 015678 625846
            <br />
            E-Mail: info@nizza-markenklasse.de
          </p>

          <h2>Hinweise zur Website</h2>

          <h3>Urheberrechtliche Hinweise</h3>

          <p>
            https://pixabay.com/&nbsp;
            <br />
            https://unsplash.com/
            <br />
          </p>

          <h2>Information gemäß § 36 VSBG</h2>

          <p>
            Die Europäische Kommission stellt eine Plattform für die
            außergerichtliche OnlineStreitbeilegung (OS-Plattform) bereit,
            aufrufbar unter{" "}
            <a href="https://ec.europa.eu/odr" data-bcup-haslogintext="no">
              https://ec.europa.eu/odr
            </a>
            . Gemäß § 36 VSBG (Verbraucherstreitbeilegungsgesetz – Gesetz über
            die alternative Streitbeilegung in Verbrauchersachen) erklärt der
            Betreiber dieser Website:
          </p>

          <p>
            Wir sind weder bereit noch verpflichtet, an
            Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
            teilzunehmen.
          </p>

          <p>
            <em>
              Das Impressum wurde mit dem&nbsp;
              <a
                href="https://www.activemind.de/datenschutz/impressums-generator/"
                data-bcup-haslogintext="no"
              >
                Impressums-Generator der activeMind AG
              </a>
              &nbsp;erstellt und um eigene Abschnitte ergänzt.
            </em>
          </p>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default ImpressumPage
